

































































import './upload.scss'
import 'vue-css-donut-chart/dist/vcdonut.css'
import Donut from 'vue-css-donut-chart'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { ITask, IDocument } from '@/interfaces/Interface'
import { documentsNamespace } from '@store/documents'
import { userNamespace } from '@store/user'
import { IDocumentTypes } from '@store/documents/Interface'
import { DocumentsActionTypes } from '@store/documents/Types'
import { IProject } from '@store/user/Interface'
import { UserActionTypes } from '@store/user/Types'

Vue.use(Donut)
interface IAuth {
  login: string
  password: string
}

@Component({
  components: {
    'v-document': () => import('@/components/Document.vue'),
    'v-card-documents': () => import('@/components/CardDocuments.vue'),
    'v-form-confirmation': () => import('@/components/forms/FormСonfirmation.vue'),
    'v-form-change': () => import('@/components/forms/FormChange.vue'),
  },
})
export default class Upload extends Vue {
  @userNamespace.Getter('project')
  private selectedProject!: number

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  private showFormConfirmation: boolean = false
  private showFormAddTask: boolean = false
  private selectedCategory: number | null = null

  private openModalAddTask(typeId: number) {
    this.selectedCategory = typeId
    this.showFormAddTask = true
  }

  // private addListWork() {
  //   this.makeAListOfWorks(this.selectedProjectId)
  //     .then(() => {
  //       this.fetchUser();
  //     });
  // }
}
